import React, { useEffect } from "react";

import Play from "./play";
import Pause from "./pause";
import Bar from "./bar";
import "./style.scss";
import useAudioPlayer from "./useAudioPlayer";
import { PlayArrow, Pause as PauseIcon } from "@material-ui/icons";

function Audio(props) {
  const { curTime, duration, playing, setPlaying, setClickedTime } =
    useAudioPlayer();
  let { link, type } = props;

  useEffect(() => {
    document.getElementById("audio").loop = false;
  }, []);

  return (
    <div className="player">
      {type === "video" ? (
        <video id="audio">
          <source src={link ? link : ""} />
        </video>
      ) : (
        <audio id="audio" loop={false}>
          <source src={link ? link : ""} />
        </audio>
      )}
      <div className="controls">
        {playing ? (
          <Pause handleClick={() => setPlaying(false)} />
        ) : (
          <Play handleClick={() => setPlaying(true)} />
        )}
        <Bar
          curTime={curTime}
          duration={duration}
          onTimeUpdate={(time) => setClickedTime(time)}
        />
      </div>
    </div>
  );
}

let timeoutId;
function AudioCustom(props) {
  const { curTime, duration, playing, setPlaying, setClickedTime, setCurTime } =
    useAudioPlayer();
  let { link, type } = props;

  useEffect(() => {
    const audio = document.getElementById("audio");
    const source = document.getElementById("audioSource");
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      audio.currentTime = 0;
      setClickedTime(0);
      setCurTime(0);
      setPlaying(false);
      source.src = link;
      audio.load();
    }, 100);
  }, [link]);

  useEffect(() => {
    document.getElementById("audio").loop = false;
  }, []);

  return (
    <div className="player-exam">
      <audio id="audio" loop={false}>
        <source id="audioSource" src={link ? link : ""} />
      </audio>
      <div className="controls">
        {playing ? (
          <button className="player__button" onClick={() => setPlaying(false)}>
            <PauseIcon />
          </button>
        ) : (
          <button className="player__button" onClick={() => setPlaying(true)}>
            <PlayArrow />
          </button>
        )}
        <Bar
          isHideTime={true}
          curTime={curTime}
          duration={duration + 0.5}
          onTimeUpdate={(time) => setClickedTime(time)}
        />
      </div>
    </div>
  );
}

export { Audio, AudioCustom };
